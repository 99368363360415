import {
    AddAddress,
    AddCoupon,
    AddItemToCart,
    AddToWishlist,
    ampli,
    CancelRedemption,
    ChangePaymentMethod,
    ChangeShipmmentAddress,
    ClickRewardsBanner,
    CloseProductPopupReview,
    CopyCodeOfRewards,
    DeleteAddress,
    EditAddress,
    ItemReviewed,
    Login,
    OpenAddressPage,
    OpenAllRewardsOffersPage,
    OpenBundleList,
    OpenCartPage,
    OpenCheckoutPage,
    OpenHowRewardsWorksPage,
    OpenInApp,
    OpenLoginPage,
    OpenLoyaltyPage,
    OpenNotificationCenterPage,
    OpenOrderDetailsPage,
    OpenOrderListPage,
    OpenOtpVerificationPage,
    OpenPopupForCashOutRewards,
    OpenProductList,
    OpenProductPopupReview,
    OpenRedemptionPopup,
    OpenRegistrationPage,
    OpenRewardsProgramPage,
    OpenTicketListPage,
    OpenTicketPageFromNotifications,
    OpenTicketPageFromTicketsList,
    OpenVideoPage,
    OpenWishlistPage,
    PlayItemVideo,
    RedeemLoyaltyPoints,
    Register,
    RemoveFromWishlist,
    Search,
    SearchForSuggestions,
    SelectBanner,
    SelectBrand,
    SelectItem,
    ShareLinkOfRewards,
    ShareRewardsBanner,
    SubmitOrder,
    SubmitOtpCode,
    VerificationSuccessful,
    ViewHomeTab,
    ClickPopup,
    SkipPopup
} from '../ampli/index';
import {useAuthStore} from "@/store/auth";
import {useCheckoutStore} from "@/store/checkout";
import {TRACKERS} from "@/constants/events";
import {gender, getCategories} from "@/helper/amplitude";
import {useCurrencyStore} from "@/store/currency";
import {useCartStore} from "~/store/cart";
import {COOKIE_KEYS} from "~/constants/cookie";

export default {
    [TRACKERS.LOGIN](user) {
        const session = useCookie(COOKIE_KEYS.SESSION, {secure: true});
        ampli.identify(user?.customer_id || '',
            {
                user_id: user?.customer_id || '',
            },
            {
                last_order_date: user.last_order_date || '',
            }, {
                gender: gender(user.gender) || '',
            }, {
                session_id: session.value,
            }, {
                birthdate: user.dob || '',
            }, {
                skin_color: parseInt(user.skin_color) || 0,
            });
    },
    [TRACKERS.INITIALIZED]() {
        const session = useCookie(COOKIE_KEYS.SESSION, {secure: true});
        const authStore = useAuthStore()
        const {isLoggedIn} = storeToRefs(authStore)

        if (!isLoggedIn)
            ampli.identify(session.value || '', {
                session_id: session.value,
            });
    },
    [TRACKERS.LOGIN_SUCCESS](loginType) {
        const authStore = useAuthStore()
        const {user, isLoggedIn} = storeToRefs(authStore)
        ampli.track(new Login({
            last_order_date: isLoggedIn
                ? user.last_order_date
                : '',
            login_type: loginType
        }))
    },
    [TRACKERS.REGISTRRATION_SUCCESS]() {
        const authStore = useAuthStore()
        const {user, isLoggedIn} = storeToRefs(authStore)
        ampli.track(new Register({
            isVerified: isLoggedIn ? user.approved === '1' : ''
        }))
    },
    [TRACKERS.SEARCH_FOR_SUGGESTIONS](key) {
        ampli.track(new SearchForSuggestions({
            search_term: key.toString()
        }))
    },
    [TRACKERS.SEARCH](params) {
        ampli.track(new Search({
            index: Number(params.index),
            search_term: params.item?.term?.toString() || params.item?.title?.toString() || "",
            search_type: params.source_name
        }))
    },
    [TRACKERS.ADD_TO_CART]({
                               product,
                               items_count = 1,
                               selectedOption = null,
                               sourceData = null,
                               quick_add = sourceData?.quick_add ?? true,
                               adjust_quantity = false
                           }) {
        let source_id = sourceData?.category_hierarchy ? getCategories(sourceData?.source_id) : sourceData?.source_id
        const currencyStore = useCurrencyStore()
        const {currency} = storeToRefs(currencyStore)

        // item bouth section for 
        let allProducts = null;
        if (Array.isArray(product)) {
            let products = []
            for (const key in product) {
                let object = {
                    id: product[key].id,
                    brand: product[key].manufacturer,
                    variant: selectedOption ? selectedOption.en_name : '',
                    name: product[key].en_name,
                    category: getCategories(product[key].category_hierarchy),
                    quantity: 1,
                    price: product[key].event_price || 0.0
                }
                products.push(object)
            }
            allProducts = products
        } else {
            //for all event sending a single product in array
            let products = [
                {
                    id: product.id,
                    brand: product.manufacturer,
                    variant: selectedOption ? selectedOption.en_name : '',
                    name: product.en_name,
                    category: getCategories(product.category_hierarchy),
                    quantity: 1,
                    price: product.event_price || 0.0
                }
            ];
            allProducts = products
        }

        ampli.track(new AddItemToCart({
            product: allProducts,
            adjust_quantity,
            currency: currency.value,
            "event_source": [sourceData],
            "event_source.source_type": sourceData?.source_type,
            "event_source.source_name": sourceData?.source_name,
            "event_source.source_id": sourceData?.source_id,
            items_count: Number(items_count),
            quick_add,
            ...sourceData,
            source_id: source_id
        }))
    },
    [TRACKERS.BANNER_CLICK]({banner, index}) {
        ampli.track(new SelectBanner({
            banner_id: banner?.banner_id,
            banner_name: banner?.en_component_name,
            'component-id': banner?.data_id + "",
            type: 'banner',
            index: Number(index)
        }));
    },
    [TRACKERS.OPEN_PRODUCT_LIST]({meta = null, page = 1}) {
        ampli.track(new OpenProductList({
            list_id: meta.value?.id?.toString(),
            list_name: meta.value?.en_name?.toString(),
            page_number: page
        }));
    },
    [TRACKERS.OPEN_OTP_VERIFICATION_PAGE]() {
        ampli.track(new OpenOtpVerificationPage())
    },
    [TRACKERS.LOGIN_INITATE]() {
        ampli.track(new OpenLoginPage())
    },
    [TRACKERS.REGISTER_INITIATE]() {
        ampli.track(new OpenRegistrationPage())
    },
    [TRACKERS.SUBMIT_OTP_CODE]() {
        ampli.track(new SubmitOtpCode())
    },
    [TRACKERS.OTP_SUCCESS]() {
        ampli.track(new VerificationSuccessful())
    },
    [TRACKERS.OPEN_WISHLIST_PAGE]() {
        ampli.track(new OpenWishlistPage())
    },
    [TRACKERS.OPEN_NOTIFICATION_CENTER_PAGE]() {
        ampli.track(new OpenNotificationCenterPage())
    },
    [TRACKERS.OPEN_ORDER_LIST_PAGE]() {
        ampli.track(new OpenOrderListPage())
    },
    [TRACKERS.OPEN_APP_FROM_SMART_BANNER]() {
        ampli.track(new OpenInApp())
    },
    [TRACKERS.OPEN_ORDER_DERAILS_PAGE](source) {
        ampli.track(new OpenOrderDetailsPage({
            order_id: source.id,
            source_name: source.name
        }))
    },
    [TRACKERS.OPEN_TICKET_LIST_PAGE]() {
        ampli.track(new OpenTicketListPage())
    },
    [TRACKERS.OPEN_TICKET_PAGE_FROM_TICKETS_LIST](ticket_id) {
        ampli.track(new OpenTicketPageFromTicketsList(ticket_id))
    },
    [TRACKERS.OPEN_TICKET_PAGE_FROM_NOTIFICATIONS](data) {
        ampli.track(new OpenTicketPageFromNotifications(data))
    },
    [TRACKERS.FAVORITE_ADD]({product}) {
        ampli.track(new AddToWishlist({
            product: [
                {
                    id: product.id,
                    brand: product.manufacturer,
                    name: product.en_name,
                    category: getCategories(product.category_hierarchy),
                    price: product.event_price ?? 0.0
                }
            ],
            in_stock: !product.stock
        }))
    },
    [TRACKERS.FAVORITE_REMOVE]({product}) {
        ampli.track(new RemoveFromWishlist({
            product: [
                {
                    id: product.id,
                    brand: product.manufacturer,
                    name: product.en_name,
                    category: getCategories(product.category_hierarchy),
                    price: product.event_price ?? 0.0
                }
            ],
            in_stock: !product.stock
        }))
    },
    [TRACKERS.PRODUCT_CLICKED]({product, sourceData = null}) {
        let source_id = sourceData?.category_hierarchy ? getCategories(sourceData?.source_id) : sourceData.source_id
        ampli.track(new SelectItem({
            product: [
                {
                    id: product.id,
                    brand: product.manufacturer,
                    name: product.en_name,
                    category: getCategories(product.category_hierarchy),
                    price: product.event_price ?? 0.0
                }
            ],
            //format need for event_source for ampli
            "event_source": [sourceData],
            "event_source.source_type": sourceData?.source_type,
            "event_source.source_name": sourceData?.source_name,
            "event_source.source_id": sourceData?.source_id,
            ...sourceData,
            source_id
        }))

    },
    [TRACKERS.CHECKOUT_OFFERS]({coupon}) {
        const checkoutStore = useCheckoutStore()
        const {cart} = storeToRefs(checkoutStore)
        ampli.track(new AddCoupon({
            cart_total: Number(cart.value?.total_raw) || 0.0,
            items_count: Number(cart.value?.total_product_count) || 0.0,
            coupon_value: coupon?.toString() || ""
        }))
    },
    [TRACKERS.OPEN_IN_APP]() {
        ampli.track(new OpenInApp())
    },
    [TRACKERS.PLAY_ITEM_VIDEO](product) {
        ampli.track(new PlayItemVideo({
            product: [
                {
                    id: product.id,
                    brand: product.manufacturer,
                    name: product.en_name,
                    category: getCategories(product.category_hierarchy),
                    price: product.event_price || 0.0
                }
            ],
            video_url: product.video_url || ''
        }))
    },
    [TRACKERS.CHECKOUT_2B_NEW_ADDRESS](data) {
        ampli.track(new AddAddress({
            address_id: data.address_id + "",
            is_confirmed: data.is_confirmed === 1
        }))
    },
    [TRACKERS.EDIT_ADDRESS](data) {
        ampli.track(new EditAddress({
            address_id: data.address_id + "",
            is_confirmed: data.is_confirmed === 1
        }))
    },
    [TRACKERS.DELETE_ADDRESS](data) {
        ampli.track(new DeleteAddress({
            address_id: data.address_id + "",
            is_confirmed: data.is_confirmed === 1
        }))
    },
    [TRACKERS.BEGIN_CHECKOUT]() {
        const checkoutStore = useCheckoutStore()
        const {cart} = storeToRefs(checkoutStore)
        const currencyStore = useCurrencyStore()
        const {currency} = storeToRefs(currencyStore)
        ampli.track(new OpenAddressPage({
            cart_total: Number(cart.value?.total_raw) || 0.0,
            currency: currency.value
        }))
    },
    [TRACKERS.CHANGE_SHIPMENT_ADDRESS]() {
        const checkoutStore = useCheckoutStore()
        const {data} = storeToRefs(checkoutStore)
        ampli.track(new ChangeShipmmentAddress({
            address_id: data.value?.address.address_id,
            is_confirmed: data.value?.address.confirmed,
            source: 'address_page'
        }))
    },
    [TRACKERS.CHANGE_PAYMENT_METHOD](payment) {
        const checkoutStore = useCheckoutStore()
        const {cart} = storeToRefs(checkoutStore)
        ampli.track(new ChangePaymentMethod({
            cart_total: Number(cart.value?.total_raw) || 0.0,
            items_count: Number(cart.value?.total_product_count) || 0.0,
            payment_method: payment.toString()
        }))
    },
    [TRACKERS.CHECKOUT_1_SHOPPING_BAG]() {
        const cartStore = useCartStore()
        const {cart, cartProducts} = storeToRefs(cartStore)
        const currencyStore = useCurrencyStore()
        const {currency} = storeToRefs(currencyStore)
        ampli.track(new OpenCartPage({
            cart_total: Number(cart.value?.total_raw) || 0.0,
            currency: currency.value,
            items_count: Number(cart.value?.total_product_count) || 0.0,
            product: cartProducts.value?.map(
                (product, number) => {
                    return {
                        id: product.product_id,
                        brand: product.manufacturer,
                        name: product.en_name,
                        category: getCategories(product.category_hierarchy),
                        quantity: 1,
                        price: product.event_price ?? 0.0
                    }
                }
            )
        }))
    },
    [TRACKERS.OPEN_CHECKOUT_PAGE](first_time) {
        const checkoutStore = useCheckoutStore()
        const {data, cart} = storeToRefs(checkoutStore)
        ampli.track(new OpenCheckoutPage({
            address_id: data.value?.address?.address_id,
            cart_total: Number(cart.value?.total_raw) || 0.0,
            defualt_payment_option: data.value?.payment?.current,
            first_time: first_time,
            items_count: Number(cart.total_product_count) || 0.0,
            product: cart.value?.products.map((product, number) => {
                return {
                    id: product.product_id,
                    brand: product.manufacturer,
                    name: product.en_name,
                    category: getCategories(product.category_hierarchy),
                    quantity: 1,
                    price: product.event_price || 0.0
                }
            })
        }))
    },
    [TRACKERS.ORDER_SUCCESS](data) {
        const authStore = useAuthStore()
        const {user} = storeToRefs(authStore)
        ampli.track(new SubmitOrder({
            $revenue: Number(data.total) || 0,
            order_id: data.order_id,
            items_count: Number(data.products.length) || 0,
            coupon_value: data.coupon || '',
            product: data.products.map((product, number) => {
                return {
                    id: product.product_id,
                    brand: product.manufacturer,
                    name: product.en_name,
                    category: getCategories(product.category_hierarchy),
                    quantity: 1,
                    price: product.event_price || 0.0
                }
            }),
            payment_method: data?.payment_code || "",
            first_order: user.total_orders === 0,
            rewards: data.reward_option?.code,
            shipmment_address_id: data.shipping_zone_id || ''
        }))
    },
    [TRACKERS.BRAND_SELECT]({brand}) {
        ampli.track(new SelectBrand({
            brand_id: brand.manufacturer_id + "",
            brand_name: brand.en_name?.toString()
        }))
    },
    [TRACKERS.VIEW_HOME_TAB]({name, tabId}) {
        ampli.track(new ViewHomeTab({
            name,
            tab_id: tabId
        }))
    },
    [TRACKERS.OPEN_PRODUCT_POPUP_REVIEW]({loyalty, orderId = '', products}) {
        ampli.track(new OpenProductPopupReview({
            loyalty_enabled: loyalty,
            order_id: orderId + "",
            product: products?.map((product, number) => {
                return {
                    id: product.product_id,
                    brand: product.manufacturer,
                    name: product.en_name,
                    quantity: 1,
                    price: product.event_price || 0.0
                }
            }),
        }))
    },
    [TRACKERS.CLOSE_PRODUCT_POPUP_REVIEW]({loyalty, orderId = '', products}) {
        ampli.track(new CloseProductPopupReview({
            loyalty_enabled: loyalty,
            order_id: orderId + "",
            product: products?.map((product, number) => {
                return {
                    id: product.product_id,
                    brand: product.manufacturer,
                    name: product.en_name,
                    quantity: 1,
                    price: product.event_price || 0.0
                }
            }),
        }))
    },
    [TRACKERS.ITEM_REVIEWED](data) {
        const {
            attachment_count = 0,
            has_description = "",
            loyalty_enabled = false,
            order_id = "",
            product = null,
            rating_value = 0
        } = data
        ampli.track(new ItemReviewed({
            attachment_count: attachment_count,
            has_description: has_description,
            loyalty_enabled: loyalty_enabled,
            order_id: order_id,
            product: [
                {
                    id: product.id || '',
                    brand: product?.manufacturer || '',
                    name: product?.en_name || '',
                    price: product?.event_price || 0.0
                }
            ],
            rating_value: rating_value
        }))
    },
    [TRACKERS.OPEN_REDEEM_POPUP]({total}) {
        ampli.track(new OpenRedemptionPopup({
            available_redeemable_points: Number(total) || 0
        }))
    },
    [TRACKERS.OPEN_lOYALTY_PAGE]({tier, total_points}) {
        console.log("tier, total_points", tier, total_points)
        ampli.track(new OpenLoyaltyPage({
            tier,
            total_points: Number(total_points) || 0
        }))
    },
    [TRACKERS.CANCEL_REDEMPTION]({canceled_points}) {
        ampli.track(new CancelRedemption({
            canceled_points: Number(canceled_points) || 0
        }))
    },
    [TRACKERS.REDEEM_LOYALTY_POINTS]({available_redeemable_points, redeemed_points}) {
        ampli.track(new RedeemLoyaltyPoints({
            available_redeemable_points: Number(available_redeemable_points) || 0,
            redeemed_points: Number(redeemed_points) || 0
        }))
    },
    [TRACKERS.OPEN_REWARDS_PROGRAM_PAGE]({total_amount}) {
        ampli.track(new OpenRewardsProgramPage({
            total_amount: Number(total_amount) || 0
        }))
    },
    [TRACKERS.OPEN_POPUP_FOR_CASH_OUT_REWARDS]({total_amount}) {
        ampli.track(new OpenPopupForCashOutRewards({
            total_amount: total_amount.toString() || ''
        }))
    },
    [TRACKERS.SHARE_LINK_OF_REWARDS]() {
        ampli.track(new ShareLinkOfRewards())
    },
    [TRACKERS.OPEN_HOW_REWARDS_WORKS_PAGE]() {
        ampli.track(new OpenHowRewardsWorksPage())
    },
    [TRACKERS.COPY_CODE_OF_REWARDS]() {
        ampli.track(new CopyCodeOfRewards())
    },
    [TRACKERS.OPEN_VIDEO_PAGE]({video_id = null, video_name = ''}) {
        ampli.track(new OpenVideoPage({
            video_id,
            video_name,

        }))
    },
    [TRACKERS.SHARE_REWARD_BANNER]({banner_id = 0, banner_title = '', banner_source = null}) {
        ampli.track(new ShareRewardsBanner({
            banner_id: Number(banner_id) || 0,
            banner_title,
            banner_source
        }))
    },
    [TRACKERS.CLICK_REWARDS_BANNER]({banner_id = 0, banner_title = '', banner_source = null}) {
        ampli.track(new ClickRewardsBanner({
            banner_id: Number(banner_id) || 0,
            banner_title,
            banner_source
        }))
    },
    [TRACKERS.OPEN_ALL_REWARDS_OFFERS_PAGE]() {
        ampli.track(new OpenAllRewardsOffersPage())
    },
    [TRACKERS.OPEN_BUNDLE_LIST]({product_id = '', offer_id = '', offer_title = ''}) {
        ampli.track(new OpenBundleList({
            offer_id,
            offer_title,
            parent_product_id: product_id,
        }))
    },
    [TRACKERS.SKIP_POPUP_MARKETING]({popup_id = '', popup_name = ''}) {
        ampli.track(new SkipPopup({popup_id, popup_name}))
    },
    [TRACKERS.CLICK_POPUP_MARKETING]({popup_id = '', popup_name = ''}) {
        ampli.track(new ClickPopup({popup_id, popup_name}))
    },
}